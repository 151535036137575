<template>
    <div>
        <div class="d-flex justify-content-center">
            <b-overlay :show="busySaving">
                <div class="d-flex flex-column align-items-center">
                    <div class="align-items-center">
                        <img src="@/assets/images/darkroots_transparent.webp" alt height="150" />
                    </div>
                    <h4>Membership Application</h4>
                    <span>Fill in your details below and you'll get an email with the next steps to become a member!</span>
                    
                    <hr />
                    <div>
                        <b-form class="mw-500 mb-4">
                            <!-- Your form groups and inputs here -->
                            <b-form-group id="example-input-group-1" label="First Name" label-for="example-input-1">
                                <b-form-input
                                    id="example-input-1"
                                    name="example-input-1"
                                    aria-describedby="input-1-live-feedback"
                                    class="input-500"
                                    v-model="$v.form.first_name.$model"
                                    :state="validateState('first_name')"
                                ></b-form-input>

                                <b-form-invalid-feedback id="input-1-live-feedback"
                                    >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                                >
                            </b-form-group>
                            <b-form-group id="example-input-group-1" label="Last Name" label-for="example-input-1">
                                <b-form-input
                                    v-model="$v.form.last_name.$model"
                                    :state="validateState('last_name')"
                                    id="example-input-1"
                                    name="last-name"
                                    aria-describedby="input-1-live-feedback"
                                ></b-form-input>

                                <b-form-invalid-feedback id="input-1-live-feedback"
                                    >This is a required field and must be at least 2 characters.</b-form-invalid-feedback
                                >
                            </b-form-group>

                            <b-form-group id="example-input-group-1" label="Contact No" label-for="example-input-1">
                                <b-form-input
                                    id="contact-no"
                                    name="contact-no"
                                    aria-describedby="input-1-live-feedback"
                                    v-model="$v.form.contact_no.$model"
                                    :state="validateState('contact_no')"
                                ></b-form-input>

                                <b-form-invalid-feedback id="input-1-live-feedback">Contact Numbers are 10 digits</b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group id="example-input-group-1" label="ID No" label-for="example-input-1">
                                <b-form-input
                                    name="id-no"
                                    aria-describedby="input-1-live-feedback"
                                    v-model="$v.form.id_no.$model"
                                    :state="validateState('id_no')"
                                ></b-form-input>

                                <b-form-invalid-feedback id="input-1-live-feedback">ID Numbers are 13 digits</b-form-invalid-feedback>
                            </b-form-group>

                             <!-- Day Input -->
                            <b-form-group label="Birth Day:" label-for="day-input">
                            <b-form-input
                                id="day-input"
                                v-model="$v.form.birth_day.$model"
                                :state="validateState('birth_day')"
                                type="number"
                                placeholder="Enter day"
                                min="1"
                                max="31"
                            ></b-form-input>
                            </b-form-group>

                            <!-- Month Input -->
                            <b-form-group label="Birth Month:" label-for="month-input">
                            <b-form-select
                                id="month-input"
                                v-model="$v.form.birth_month.$model"
                                :state="validateState('birth_month')"
                                :options="months"
                            ></b-form-select>
                            </b-form-group>

                            <!-- Year Input -->
                            <b-form-group label="Birth Year:" label-for="year-input">
                            <b-form-input
                                id="year-input"
                                v-model="$v.form.birth_year.$model"
                                :state="validateState('birth_year')"
                                type="number"
                                placeholder="Enter year"
                                min="1900"
                                max="2023"
                            ></b-form-input>
                            </b-form-group>

                            <b-form-group label="Address">
                            <b-form-input 
                                v-model="$v.form.street.$model"
                                :state="validateState('street')"
                                placeholder="123 Main St"
                            ></b-form-input>
                            </b-form-group>

                            <b-form-group label="City">
                            <b-form-input 
                                v-model="$v.form.city.$model"
                                :state="validateState('city')"
                                placeholder="City"
                            ></b-form-input>
                            </b-form-group>

                            <b-form-group label="Postal Code">
                            <b-form-input 
                                v-model="$v.form.postal_code.$model"
                                :state="validateState('postal_code')"
                                placeholder="Postal Code"
                            ></b-form-input>
                            </b-form-group>

                            <b-form-group label="Province">
                                <b-form-select
                                    v-model="$v.form.province.$model"
                                    :state="validateState('province')"
                                    :options="province_options"
                                    id="province"
                                    name="province"
                                    aria-describedby="client-type-live-feedback"
                                ></b-form-select>
                                <b-form-invalid-feedback id="client-type-live-feedback">This is a required field.</b-form-invalid-feedback>
                            </b-form-group>

                            
                            <b-form-group id="example-input-group-1" label="Email" label-for="example-input-1">
                                <b-form-input
                                    v-model="$v.form.email.$model"
                                    :state="validateState('email')"
                                    type="email"
                                    id="email"
                                    name="email"
                                    aria-describedby="input-1-live-feedback"
                                ></b-form-input>

                                <b-form-invalid-feedback id="input-1-live-feedback">Needs to be a valid email format</b-form-invalid-feedback>
                            </b-form-group>



                            <b-form-checkbox
                                id="checkbox-1"
                                v-model="form.newsletter"
                                name="checkbox-1"
                                value="true"
                                unchecked-value="false"
                                size="lg"
                                class="float-left"
                            >
                                Newsletter
                            </b-form-checkbox>
                        </b-form>
                        <div class="modal-footer mt-4">
                            <b-button @click="saveLead" variant="primary">Submit Application</b-button>
                            <b-button @click="clear" variant="warning">Clear / Cancel</b-button>
                        </div>
                    </div>
                </div>
                
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { crmMethods, crmComputed } from "@/state/helpers";
//eslint-disable-next-line
import { required, between, minLength, maxLength, email } from "vuelidate/lib/validators";
//eslint-disable-next-line
import Swal from "sweetalert2";

export default {
    data: () => ({
        busySaving: false,
        formSubmit: false,
        form: {
            first_name: "",
            last_name: "",
            contact_no: "",
            id_no: "",
            email: "",
            province: null,
            birth_day: "",
            birth_month: null,
            birth_year: "",
            street: "",
            city: "",
            postal_code: "",
            type: null,
            newsletter: "false",
        },
        months: [
            { value: null, text: 'Select month' },
            { value: '01', text: 'January' },
            { value: '02', text: 'February' },
            { value: '03', text: 'March' },
            { value: '04', text: 'April' },
            { value: '05', text: 'May' },
            { value: '06', text: 'June' },
            { value: '07', text: 'July' },
            { value: '08', text: 'August' },
            { value: '09', text: 'September' },
            { value: '10', text: 'October' },
            { value: '11', text: 'November' },
            { value: '12', text: 'December' }
        ],
        province_options: [
            { value: null, text: "Please select an option" },
            { value: "Western Cape", text: "Western Cape" },
            { value: "Eastern Cape", text: "Eastern Cape" },
            { value: "Free State", text: "Free State" },
            { value: "Gauteng", text: "Gauteng" },
            { value: "KwaZulu-Natal", text: "KwaZulu-Natal" },
            { value: "Limpopo", text: "Limpopo" },
            { value: "Mpumalanga", text: "Mpumalanga" },
            { value: "North-West", text: "North-West" },
            { value: "Northern-Cape", text: "Northern-Cape" },
        ],
        type_options: [
            { value: null, text: "Please select an option" },
            { value: "stockist", text: "Stockist" },
            { value: "reseller", text: "Representative / Reseller" },
            { value: "individual", text: "Individual" },
        ],
    }),
    validations: {
        form: {
            first_name: {
                required,
                minLength: minLength(2),
            },
            last_name: {
                required,
                minLength: minLength(2),
            },
            contact_no: {
                required,
                minLength: minLength(10),
                maxLength: maxLength(10),
            },
            id_no: {
                required,
                minLength: minLength(13),
                maxLength: maxLength(13),
            },
            birth_day: { required, between: between(1, 31) },
            birth_month: { required },
            birth_year: { required, between: between(1900, new Date().getFullYear()) },
            email: {
                required,
                email,
            },
            province: {
                required,
            },
            street: {
                required,
            },
            city: {
                required,
            },
            postal_code: {
                required,
            },
        },
    },
    computed: {
        ...crmComputed,
    },
    methods: {
        ...crmMethods,
        refreshPage() {
            window.location.reload();
        },
        isTwentyOneOrOlder(dateString) {
            const birthDate = new Date(dateString);
            const today = new Date();
            let age = today.getFullYear() - birthDate.getFullYear();
            const m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
            }
            return age >= 21;
        },
        focusDatePicker() {
            this.$nextTick(() => {
                if (this.$refs.datePickerInput && this.$refs.datePickerInput.$el) {
                    this.$refs.datePickerInput.$el.click();
                }
            });
        },
        clear() {
            Swal.fire({
                title: `Clear the form?`,
                text: `Are you sure?`,
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Confirm",
            }).then((result) => {
                if (result.value) {
                    //
                    this.reset();
                }
            });
        },
        reset() {
            this.form = {
                first_name: "",
                last_name: "",
                contact_no: "",
                id_no: "",
                email: "",
                province: null,
                dob: "",
                street: "",
                city: "",
                postal_code: "",
                type: null,
                newsletter: "false",
            };
            this.formSubmit = false;
            this.busySaving = false;
        },
        validateState(name) {
            if (this.formSubmit) {
                const { $dirty, $error } = this.$v.form[name];
                return $dirty ? !$error : null;
            }
        },
        saveLead() {
            this.formSubmit = true;

            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }
            let birthday = this.form.birth_year + "-" + this.form.birth_month + "-" + this.form.birth_day;
            if (!this.isTwentyOneOrOlder(birthday)) {
                Swal.fire(`You need to be at least 21 years old`, "", "error");
                return;
            }
            this.busySaving = true;
            this.form.dob = birthday;
            this.form.newsletter == "false" ? (this.form.newsletter = false) : (this.form.newsletter = true);
            this.saveIncomingSalesLead(this.form).then(() => {
                Swal.fire(`Application Succesful`, "", "success");
                this.busySaving = false;
                this.reset();
            });
        },
    },
    mounted() {},
};
</script>

<style lang="scss" scoped>
.mw-500 {
    max-width: 500px !important;
}
.input-500 {
    width: 500px;
}

@media only screen and (max-width: 510px) {
    .input-500 {
        width: 300px;
    }
}
@media only screen and (max-width: 310px) {
    .input-500 {
        width: 200px;
    }
}
</style>

<script>
import {
    layoutComputed, adminMethods, adminComputed
} from "@/state/helpers";
import Vertical from "./vertical-no-menu";

export default {
    components: {
        Vertical,
    },
    data() {
        return {};
    },
    computed: {
        ...layoutComputed,
        ...adminComputed,
        hasCoreLoaded() {
            return this.core_loaded;
        },
        ready() {
            if (Object.keys(this.core).length > 0 && this.core_loaded) {
                return true;
            }
            return false;
        }
    },
    methods: {
        ...adminMethods
    },
    mounted() {
        //if(!this.ready || this.is_core_loading == false) {
        //    this.loadCoreData();
        //}
    }
};
</script>

<template>
<div>
    <Vertical :layout="layoutType">
        <slot />
    </Vertical>
</div>
</template>
